#map
{
width:100vw;
height:100vh;
margin:0;
padding:0;
background-color: #FFFFFF;
}


.title {
    display: flex;
      flex-direction: column;
    justify-content: center;
    align-items: center;
}

.spinner {
  font-size:50px;
  animation: spin infinite 5s linear;

  /*You can increase or decrease the timer (5s) to 
   increase or decrease the speed of the spinner*/
}
.fade {
  animation: fade  0.5s linear;
}

@keyframes fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
  }
@keyframes spin {
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
}

.child {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  /* Backward compatibility */
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}


.carousel .control-dots .dot {

  margin: 0 4px !important;
}